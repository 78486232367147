<template>
  <van-button type="primary" class="van-icon-scan scan-btn" icon-position="right" @click="$router.push({name: 'Scan'})">扫一扫</van-button>
  <van-field type="textarea" autosize placeholder="扫描结果" v-model="qr_result" />
</template>

<script>
// @ is an alias to /src
import ls from '@/utils/localStorage'

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      qr_result: ls.getItem('me_scan_code_result')
    }
  }
}
</script>
<style scoped>
.scan-btn {
  float: right;
  right: 1rem;
  margin-top: 2rem;
}
</style>
